import React from "react"
import { Paragraph, Link } from "evergreen-ui"
import StartButton from "../components/StartButton"

export default ({ location }) => {
  return (
    <>
      <Paragraph size={500} marginTop="default">
        Szanowni Państwo,
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Dziękujemy za zainteresowanie dołączeniem do sieci Serwisów Partnerskich
        współpracujących z Goodyear w ramach sklepu internetowego. Serwisy
        Partnerskie obsługują klientów ostatecznych, którzy dokonali zakupu
        online za pośrednictwem sklepu internetowego należącego do Goodyear. W
        ramach współpracy otrzymują Państwo dla każdej transakcji wynagrodzenie
        za usługę montażu oraz dodatkowo wynagrodzenie do opony. Zachęcamy do
        zapoznania się z załączonym{" "}
        <Link href="/videos/pl/video.mp4" target="_blank" size={500}>
          filmikiem
        </Link>{" "}
        i{" "}
        <Link
          href="/manuals/sklep_goodyear.pl_manual_dla_serwisu.pdf"
          target="_blank"
          size={500}
        >
          instrukcją
        </Link>
        , by dowiedzieć się więcej o programie.
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Aby zarejestrować się w programie, należy ukończyć wszystkie kroki
        procesu rejestracji przez Internet.
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Po zakończeniu rejestracji otrzymają Państwo, jak i Goodyear kopię
        wszystkich przesłanych danych. Następnie Goodyear przystąpi do
        weryfikacji rejestracji. Po zakończonej weryfikacji Goodyear poinformuje
        Państwa, czy zostali Państwo aktywowani jako Serwis Partnerski
        współpracujący z Goodyear w ramach sklepu internetowego.
      </Paragraph>

      <Paragraph size={500} marginTop="default" marginBottom="default">
        Dziękujemy raz jeszcze za zainteresowanie programem Goodyear. Mamy
        nadzieję powitać Cię wkrótce jako Serwis Partnerski.
      </Paragraph>
      <StartButton search={location.search} />
    </>
  )
}
